<template>
  <div>
    <div class="container">
      <v-card flat class="style_card_container">
        <v-icon class="close-button" @click="closeWindow">close</v-icon>
        <h2 class="inquiry-title" v-if="inquiries.length">Select Style</h2>
          <v-row v-if="inquiries.length">
              <div
                v-for="inquiry in inquiries"
                :key="inquiry.id"
                class="miniInquiry col-12 col-sm-12 col-md-6 col-lg-4"
              >
                <mini-inquiry :inquiry="inquiry" @inquiry-clicked="inquiryClicked"></mini-inquiry>
              </div>
          </v-row>
          <v-row v-else class="no-inquiry-msg">
                <h2 >Style not Available</h2>
              </v-row>
      </v-card>
    </div>
  <div class="popup-container">
    <v-dialog persistent v-model="subTaskWindow" width="80%">
      <v-card width="100%">
        <SelectStyleFileSubTask
          :active="subTaskWindow"
          :file="filename"
          :inquiryId="inquiryId"
          @close="closeWindow"
        />
      </v-card>
    </v-dialog>
  </div>
</div>
</template>

<script>

import MiniInquiry from "../Inquiry/MiniInquiry.vue";
import SelectStyleFileSubTask from '../Inquiry/SelectStyleFileSubTask.vue';

export default {
  name: "SelectInquiry",
  components: {
    MiniInquiry,
    SelectStyleFileSubTask
  },

  data: () => ({
    subTaskWindow : false,
    inquiryId:null,
    filename:null,
  }),

  props: {
    inquiries: {
      type: Array,
      default:()=>[]
    },
    file:{
      type: Object,
    },
  },

  methods: {
    closeWindow(){
      this.subTaskWindow = false;
      this.inquiryId = null,
      this.file = null
      this.$emit("close");
    },
    inquiryClicked(id){
      this.$emit("close");
      this.subTaskWindow = true;
      this.inquiryId = id;
      this.filename = this.file
    }
  },

}
</script>

<style lang="scss" scoped>
.style_card_container{
width: 100%;
height: auto;
padding: 0px;
overflow-x: hidden;
overflow-y: hidden;
background-color: #eef1fa;
}
.inquiry-title{
  background-color: $primary;
  color: #eef1fa;
}
.no-inquiry-msg{
  justify-content: center;
  padding: 6px;
}
.miniInquiry{
  margin-top: 10px;
}
.close-button {
  position: absolute;
  float: right;
  top: 5px;
  right: 5px;
  background-color: #eef1fa;
}

</style>
