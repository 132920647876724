<template>
  <v-container id="collection" fluid tag="section">
    <div v-if="collectionDataLoading" class="my-5">
      <md-progress-spinner
        class="color_stroke"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <v-container v-else>
      <v-tabs v-model="activeTab" light grow show-arrows active-class="active">
        <v-tabs-slider class="tabs-slider"></v-tabs-slider>
        <v-tab href="#collection">
          <base-icon
            width="29"
            height="29"
            viewBox="0 0 29 29"
            :active="activeTab === 'collection'"
            v-slot="props"
          >
            <project-board :fill="props.slotColor" />
          </base-icon>
          &nbsp;Collection
        </v-tab>
        <v-tab href="#styles">
          <base-icon
            width="40"
            height="50"
            viewBox="0 -11 10 35"
            :active="activeTab === 'styles'"
            v-slot="props"
          >
            <style-list :fill="props.slotColor" />
          </base-icon>
          &nbsp;Styles
        </v-tab>
        <v-tab href="#about">
          <base-icon
            width="29"
            height="29"
            viewBox="0 0 29 29"
            :active="activeTab === 'about'"
            v-slot="props"
          >
            <project-board :fill="props.slotColor" />
          </base-icon>
          &nbsp;About
        </v-tab>
        <v-tab
          v-if="(!editMode && isOwner()) || getIsAuthUser()"
          href="#options"
          @click="toggleOptions"
        >
          <base-icon
            width="26"
            height="26"
            viewBox="0 0 26 26"
            :active="activeTab === 'options'"
            v-slot="props"
          >
            <options-icon :fill="props.slotColor" />
          </base-icon>
          &nbsp;Options
        </v-tab>
      </v-tabs>
      <v-tabs-items class="tabs-items" v-model="activeTab">
        <v-tab-item value="collection">
          <v-card flat class="timeline_card_container">
            <Timeline
              :collectionId="collectionId"
              :isAuthorizedToChangeTimeLine="getIsAuthUser()"
              :refreshTimeline="timelineDataChangeFromPrevious"
            />
          </v-card>
          <v-card
            flat
            class="chat_card_container"
            style="background-color: transparent"
          >
            <v-row justify="center">
              <v-col class="col-lg-9 col-12" style="padding-left: 0px">
                <chat
                  style="padding-top: 0"
                  :collectionId="collectionData.id"
                  :assigned-suppliers="assignedSuppliers"
                  @file-upload-status="refreshFiles"
                  @chat-id="updateChatId"
                  :startingRoomId="Number($route.query.room_id)"
                  @refresh-attachments="refreshFiles"
                  :activeTab="activeTab"
                />
              </v-col>

              <v-col class="col-lg-3 col-12">
                <collection-side-bar
                  :collectionId="collectionId"
                  :inquiries="collectionData.inquiries"
                  :files="files"
                  :userId="userId"
                  :isBrandOrCreator="isBrandOrCreator"
                  @delete="deleteFile"
                  @set-default="setDefaultImage"
                  @download="fileDownload"
                  @file-uploaded="refreshFiles"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item value="styles">
          <v-card flat class="style_card_container">
            <v-row>
              <div
                class="createBtn-container col-md-4 col-xs-1"
                v-if="isBrandOrCreator"
              >
                <button-card class="btn" @click="openInquiry">
                  <div class="button_card_items">
                    <ellipse-icon
                      class="button_card_icon"
                      :width="`45px`"
                      :height="`45px`"
                      ><v-icon style="color: #582963" large
                        >add</v-icon
                      ></ellipse-icon
                    >
                    <span>Create New Style</span>
                  </div>
                </button-card>
              </div>

              <div
                v-for="inquiry in collectionData.inquiries"
                :key="inquiry.id"
                class="miniInquiry col-md-4 col-xs-1"
              >
                <mini-inquiry
                  :inquiry="inquiry"
                  :inquiryShow="true"
                ></mini-inquiry>
              </div>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item
          v-if="(!editMode && isOwner()) || getIsAuthUser()"
          value="options"
        >
          <v-card
            flat
            class="service_card_container service_buttons_container"
            v-if="!isSuperAdmin()"
          >
            <v-row justify="center" v-if="showButtons">
              <button-card
                class="service_button_card"
                @click="switchEditMode"
                v-if="(!editMode && isOwner()) || getIsAuthUser()"
              >
                <div class="button_card_items">
                  <ellipse-icon class="button_card_icon"
                    ><edit-inquiry
                  /></ellipse-icon>
                  <span>Edit Collection</span>
                </div>
              </button-card>

              <button-card
                class="service_button_card"
                variant="danger-theme"
                id="deleteInquiryButton"
                @click="deleteCollection(collectionData.id)"
                v-if="(!editMode && isOwner()) || getIsAuthUser()"
              >
                <div class="button_card_items">
                  <ellipse-icon
                    class="button_card_icon"
                    variant="danger"
                    v-slot="props"
                    ><delete-icon :fill="props.fill"
                  /></ellipse-icon>
                  <span>Delete Collection</span>
                </div>
              </button-card>

              <!-- <button-card
                class="service_button_card"
                @click="addInquiryToCollection"
                v-if="isAccountManagerAssigned === true || getIsAuthUser()"
              >
                <div class="button_card_items">
                  <ellipse-icon class="button_card_icon button_icon">
                    <v-icon>mdi-playlist-play </v-icon>
                  </ellipse-icon>
                  <span>Move Styles</span>
                </div>
              </button-card> -->
              <!-- <button-card
                class="service_button_card"
                @click="selectCollectionNumber"
                v-if="isAccountManagerAssigned === true"
                id="importTimelineButton"
              >
                <div class="button_card_items">
                  <ellipse-icon class="button_card_icon"
                    ><style-icon />
                    <v-icon
                      style="
                        color: #582963;
                        margin-left: 3px;
                        margin-bottom: 1px;
                      "
                      large
                      >library_add</v-icon
                    >
                  </ellipse-icon>
                  <span>Import Timeline</span>
                </div>
              </button-card> -->
            </v-row>
            <v-row justify="center" v-else>
              <v-col cols="12" lg="9" v-if="importTimeline">
                <div style="text-align: left">
                  <div
                    class="back_btn_class text_color"
                    @click="
                      importTimeline = false;
                      showButtons = true;
                    "
                  >
                    <v-icon class="arrow_icon">chevron_left</v-icon>
                    <span class="mr-1">Back to Options</span>
                  </div>
                </div>
                <div>
                  <v-autocomplete
                    type="text"
                    label="Collection Number"
                    v-model="timelineCollectionNumber"
                    :items="collectionNumbers"
                    item-text="collection_number"
                    item-value="id"
                  >
                    <template v-slot:item="{ item }">
                      <span class="list-content">{{
                        item.collection_number
                      }}</span>
                    </template>
                    <template v-slot:selection="{ item }">
                      <span>{{ item.collection_number }}</span>
                    </template>
                  </v-autocomplete>
                  <v-btn
                    @click="openTimelineWarning = true"
                    :disabled="!timelineCollectionNumber"
                  >
                    Import
                  </v-btn>
                </div>
                <v-dialog
                  v-model="openTimelineWarning"
                  persistent
                  max-width="400"
                >
                  <v-card>
                    <v-card-text class="text-h3">
                      This action will add new critical path and remove current
                      critical path.
                    </v-card-text>
                    <div class="text-h5">Would you like to continue</div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="purple darken-1"
                        text
                        @click="openTimelineWarning = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn color="error" @click="ApprovedReplaceTimeline">
                        Ok
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>

              <v-col cols="12" lg="9" v-if="editMode">
                <create-collection
                  :collectionName="collectionName"
                  :selectedCurrency="selectedCurrency"
                  @update-event="offSwitchEditMode"
                ></create-collection>
              </v-col>
              <v-col cols="12" lg="9" v-if="addInquiryWindow">
                <v-row>
                  <v-col cols="12">
                    <div style="text-align: left">
                      <div
                        class="back_btn_class text_color"
                        @click="
                          addInquiryWindow = false;
                          showButtons = true;
                        "
                      >
                        <v-icon class="arrow_icon">chevron_left</v-icon>
                        <span class="mr-1">Back to Options</span>
                      </div>
                    </div>
                    <div class="move-collection-title">
                      <span class="title-text display-1"
                        >Move styles to this collection</span
                      >
                    </div>
                    <collection-list
                      :collection="collectionData"
                      :newStyleButtonShow="false"
                    ></collection-list>
                  </v-col>
                </v-row>
                <md-card-content class="search-content">
                  <v-row class="inquiry-search-bar">
                    <v-col xl="4" lg="4" xs="6">
                      <v-text-field
                        label="Search Styles"
                        v-on:input="debounceSearch"
                        name="search"
                        id="search"
                        v-model="inquirySearchQuery"
                        clearable
                      />
                    </v-col>
                    <v-col xl="4" lg="4" xs="6">
                      <md-field class="search_field_class">
                        <label for="product_type">Product Type</label>
                        <md-select
                          @input="getInquiries"
                          data-cy="product-type"
                          name="product-type"
                          id="product-type"
                          v-model="productType"
                          placeholder="Product Type"
                          :disabled="inquiryLoading"
                        >
                          <div class="search_type_container">
                            <md-option
                              class="list_color"
                              v-for="productType in productTypes"
                              :key="'producttype-' + productType.id"
                              :value="productType.id"
                              >{{ productType.name }}
                            </md-option>
                          </div>
                        </md-select>
                      </md-field>
                    </v-col>
                  </v-row>
                </md-card-content>
                <v-row class="inquiry-container">
                  <div
                    v-for="inquiry in inquiries"
                    :key="inquiry.id"
                    class="miniInquiry col-11 col-sm-9 col-md-6 col-lg-6"
                  >
                    <mini-inquiry
                      :inquiry="inquiry"
                      :currentCollectionNumber="currentCollectionNumber"
                      :addButtonShow="true"
                      @move="refresh()"
                    ></mini-inquiry>
                  </div>
                </v-row>
                <Pagination
                  style="margin-top: 15px"
                  :current-page="currentPage"
                  :total-count="totalCount"
                  @load-page="loadPage"
                  v-if="inquiries.length"
                />
                <div v-if="inquiryLoading" class="my-5">
                  <md-progress-spinner
                    class="color_stroke"
                    md-mode="indeterminate"
                  ></md-progress-spinner>
                </div>
                <v-row v-if="!inquiryLoading && inquiries.length === 0">
                  <v-col cols="12"> No Styles found. </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            flat
            class="service_card_container service_buttons_container"
            v-else
          >
            <h3 class="error_message">No options available</h3>
          </v-card>
        </v-tab-item>
        <v-tab-item value="about">
          <v-card flat class="service_card_container">
            <v-col cols="12">
              <md-content>
                <div id="collectionDetails">
                  <div
                    class="md-subheading mb-5 subheading_color py-3 white--text"
                  >
                    Collection Details
                  </div>
                  <v-row>
                    <v-col class="form-label">
                      <label>Collection Number:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label class="font-weight-bold">{{
                        collectionData.collection_number
                      }}</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="form-label">
                      <label>Name:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label class="font-weight-bold">
                        {{ collectionData.name }}
                      </label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="form-label">
                      <label>Brand:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label
                        class="font-weight-bold brand-listing"
                        @click="
                          openCompanyProfile(
                            collectionData.brand_organization.uuid
                          )
                        "
                        >{{ collectionData.brand_organization.name }}</label
                      >
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="form-label">
                      <label>Created at:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label class="font-weight-bold">{{
                        formatDate(collectionData.created_at)
                      }}</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="form-label">
                      <label>Updated at:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label class="font-weight-bold">{{
                        formatDate(collectionData.updated_at)
                      }}</label>
                    </v-col>
                  </v-row>

                  <div>
                    <div
                      class="md-subheading my-5 subheading_color py-3 white--text"
                    >
                      Added Suppliers
                    </div>
                    <div
                      v-if="
                        collectionData.collection_suppliers &&
                        collectionData.collection_suppliers.length > 0
                      "
                    >
                      <v-row
                        v-for="supplier in collectionData.collection_suppliers"
                        v-bind:key="supplier.id"
                      >
                        <v-col cols="12" class="form-value">
                          <!-- <router-link
                            class="router-link"
                            :to="'/app/business_profile/' + supplier.id"
                          > -->
                          <div
                            class="supplier-listing"
                            @click="openCompanyProfile(supplier.supplier.uuid)"
                          >
                            {{ supplier.supplier.name }}
                          </div>
                          <!-- </router-link> -->
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <label class="form-value font-weight-bold">
                        No suppliers added yet
                      </label>
                    </div>
                  </div>
                </div>
              </md-content>
            </v-col>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import MiniInquiry from "../Inquiry/MiniInquiry.vue";
import CreateCollection from "./CreateCollection.vue";
import Timeline from "../Inquiry/Timeline.vue";
import Chat from "../Chat.vue";
import restAdapter from "../../restAdapter";
import auth from "../../auth";
import * as conf from "../../conf.yml";
import CollectionSideBar from "./CollectionSideBar.vue";
import notification from "../../notification";
import CollectionList from "./CollectionList.vue";
import Pagination from "../../components/Pagination";
import debounce from "lodash/debounce";
import moment from "moment";
import routes from "../../router/routes";
import store from "@/store";

export default {
  name: "Collection",

  components: {
    MiniInquiry,
    CreateCollection,
    CollectionSideBar,
    Chat,
    Timeline,
    CollectionList,
    Pagination,
  },

  data: () => ({
    timelineDataChangeFromPrevious: false,
    openTimelineWarning: false,
    activeTab: "styles",
    userId: null,
    collectionId: null,
    timelineCollectionNumber: null,
    collectionNumbers: null,
    collectionName: "",
    currentCollectionNumber: "",
    selectedCurrency: "",
    editMode: true,
    collectionData: {},
    files: [],
    assignedSuppliers: [],
    collectionDataLoading: true,
    isAccountManagerAssigned: false,
    addInquiryWindow: false,
    inquiries: [],
    query: "",
    productType: 0,
    currentPage: 1,
    totalCount: 0,
    showButtons: true,
    refreshTimeline: false,
    isCollectionTabVisible: false,
    productTypes: [],
    inquiryLoading: false,
    chatId: null,
    inquirySearchQuery: "",
    importTimeline: false,
    assignMode: false,
  }),

  beforeRouteUpdate(to, from, next) {
    window.scrollTo(0, 0);
    next();
  },
  mounted() {
    this.collectionDataLoading = true;
    this.getProductTypes();
    this.userId = auth.user.id;
    if (this.$route.query.room_id || this.$route.query.msg) {
      this.activeTab = "collection";
      this.getCollectionData();
    } else {
      this.getCollectionData(true);
    }
  },
  computed: {
    isBrandOrCreator() {
      return (
        auth.user.account.organization_registry_id ===
          this.collectionData.brand_id ||
        auth.user.account.organization_registry_id ===
          this.collectionData.created_by_organization_id
      );
    },
  },

  methods: {
    formatDate(date) {
      return moment(date).format("HH:mm Do MMMM YYYY");
    },
    selectCollectionNumber() {
      this.getCollectionNumber();
      if (this.importTimeline === true) {
        this.importTimeline = false;
      } else {
        this.showButtons = false;
        this.importTimeline = true;
      }
    },
    getCollectionNumber() {
      const url =
        `/api/collections/customer/${this.collectionData.brand_id}?` +
        "&exclude_collection=" +
        this.collectionId;
      restAdapter
        .get(url)
        .then((response) => {
          this.collectionNumbers = response.data;
        })
        .catch((error) => {
          notification.error(error);
        });
    },
    openCompanyProfile(uuid) {
      window.open(`${window.location.origin}/#/company/${uuid}`, "_blank");
    },
    // ApprovedReplaceTimeline(){
    //   restAdapter
    //     .post(`/api/collections/${this.collectionId}/import_package/${this.timelineCollectionNumber}`)
    //     .then((response) => {
    //       this.timelineDataChangeFromPrevious = !this.timelineDataChangeFromPrevious ;
    //       notification.success("Timeline imported successfully.");
    //     })
    //     .catch((error) => {
    //       notification.error("Something went wrong.");
    //     });
    //   this.openTimelineWarning = false;
    //   this.showButtons = true;
    //   this.importTimeline = false;
    //   this.timelineCollectionNumber = null;
    // },
    toggleOptions() {
      this.showButtons = true;
      this.editMode = false;
    },
    switchEditMode() {
      if (this.editMode === true) {
        this.editMode = false;
        this.showButtons = true;
      } else {
        this.editMode = true;
        this.showButtons = false;
        this.addInquiryWindow = false;
        this.collectionName = this.collectionData.name;
        this.selectedCurrency = this.collectionData.currency;
      }
    },

    switchEditModeImport() {
      if (this.editMode === true) {
        this.editMode = false;
        this.showButtons = true;
      } else {
        this.editMode = true;
        this.showButtons = false;
        this.addInquiryWindow = false;
        this.collectionName = this.collectionData.name;
      }
    },

    offSwitchEditMode() {
      this.switchEditMode();
      this.getCollectionData();
      this.collectionName = this.collectionData.name;
      this.selectedCurrency = this.collectionData.currency;

      this.refreshTimeline = true;
      this.timelineDataChangeFromPrevious =
        !this.timelineDataChangeFromPrevious;
    },

    addInquiryToCollection() {
      this.getInquiries();
      this.showButtons = false;
      this.editMode = false;
      this.addInquiryWindow = true;
    },

    getCollectionData(changeActiveTab = false) {
      this.collectionId = this.$route.params.id;
      restAdapter
        .get(
          `/api/collection/${this.collectionId}${
            this.chatId ? `?chat_id=` + this.chatId : ""
          }`
        )
        .then((response) => {
          this.collectionData = response.data.collection_data;
          const allSuppliers =
            response.data.collection_data.collection_suppliers?.map(
              (supplier) => ({
                id: supplier.supplier.id,
                name: supplier.supplier.name,
                avatar: supplier.supplier.account?.avatar,
                role:
                  supplier.supplier.type_details.short_code === "supplier"
                    ? "Supplier"
                    : "Brand",
              })
            ) || [];

          allSuppliers.push({
            id: response.data.collection_data.brand_organization.id,
            name: response.data.collection_data.brand_organization.name,
            avatar:
              response.data.collection_data.brand_organization.account?.avatar,
            role:
              response.data.collection_data.brand_organization.type_details
                .short_code === "supplier"
                ? "Supplier"
                : "Brand",
          });

          allSuppliers.push({
            id: response.data.collection_data.created_by_organization.id,
            name: response.data.collection_data.created_by_organization.name,
            avatar:
              response.data.collection_data.created_by_organization.account
                ?.avatar,
            role:
              response.data.collection_data.created_by_organization.type_details
                .short_code === "supplier"
                ? "Supplier"
                : "Brand",
          });

          const uniqueKeys = {};
          allSuppliers.map((supplier) => {
            uniqueKeys[supplier.id] = supplier;
            return supplier;
          });

          this.assignedSuppliers = Object.values(uniqueKeys);

          this.currentCollectionNumber = this.collectionData.collection_number;
          this.files = response.data.files;
          this.collectionDataLoading = false;
          if (this.collectionData) {
            store.commit("SET_UPDATE_COLLECTION_IN_APP_BAR", {
              collectionNumber: this.currentCollectionNumber,
              collectionName: this.collectionData.name,
            });
          }

          if (
            this.collectionData.account_managers?.find(
              (manager) => manager.id == auth.user.id
            )
          ) {
            this.isAccountManagerAssigned = true;
          }
          if (changeActiveTab) {
            this.activeTab =
              this.collectionData.inquiries.length == 0
                ? "styles"
                : "collection";
          }
          this.setIsCollectionTabVisible();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            notification.error("Collection not found");
            this.$router.push({ name: routes.COLLECTION_LIST });
          }
          if (error.response.status === 403 || error.response.status === 401) {
            notification.error(
              "You are not authorized to view this collection"
            );
            this.$router.push({ name: routes.COLLECTION_LIST });
          }
        });
    },
    setIsCollectionTabVisible() {
      this.isCollectionTabVisible =
        this.isOwner() ||
        this.isCollectionAccountManager() ||
        this.isCollectionSupplier() ||
        this.isSuperAdmin();
    },
    async getProductTypes() {
      await restAdapter.get("/api/inquire_product_types").then((response) => {
        this.productTypes = response.data.productTypes;
        this.productTypes.unshift({ id: 0, name: "All" });
      });
    },
    isOwner() {
      return this.collectionData.brand_id === auth.user.id;
    },
    getIsCustomer() {
      return auth.user.role["name"] === "supplier_account_manager";
    },
    isCollectionAccountManager() {
      if (!this.collectionData?.account_managers?.length) return false;
      if (
        this.collectionData.account_managers.find(
          (manager) => manager.id === auth.user.id
        )
      )
        return true;
    },
    deleteFile(id) {
      if (confirm("Delete this file?")) {
        restAdapter.delete("/api/collections/file_delete/" + id).then(() => {
          notification.success("File deleted successfully");
          this.refreshFiles();
        });
      }
    },
    updateChatId(chatId) {
      this.chatId = chatId;
      this.getCollectionData();
      return chatId;
    },
    setDefaultImage(id) {
      restAdapter
        .post(`/api/collection/${this.collectionId}/set_default_image/`, {
          // eslint-disable-next-line @typescript-eslint/camelcase
          default_image: id,
        })
        .then(() => {
          notification.success("Image set as default thumbnail successfully");
        });
    },

    fileDownload(file) {
      window.open(
        `${conf.server.host}/api/collections/file_download/${file.upload_name}`
      );
    },
    refreshFiles() {
      this.getCollectionData();
    },
    getIsAuthUser() {
      return (
        auth.user.account.organization_registry["id"] ===
          this.collectionData.brand_id ||
        auth.user.account.organization_registry["id"] ===
          this.collectionData.created_by_organization_id
      );
    },

    isCollectionSupplier() {
      if (!this.collectionData?.collection_suppliers?.length) return false;
      return this.collectionData.collection_suppliers.find(
        (supplier) => supplier.id === auth.user.id
      );
    },

    isSuperAdmin() {
      return this.collectionData.super_admin?.id === auth.user.id;
    },

    getInquiries() {
      this.inquiryLoading = true;
      let url = "/api/inquiries?page=" + this.currentPage;
      url +=
        "&brand_id=" +
        this.collectionData.brand_id +
        "&exclude_collection=" +
        this.collectionId +
        "&query=" +
        (this.inquirySearchQuery == null ? "" : this.inquirySearchQuery) +
        "&product_type=" +
        (this.productType ? this.productType : 0) +
        "&only_assigned_to_me";

      restAdapter.get(url).then((response) => {
        this.inquiries = response.data.data;
        this.totalCount = response.data.meta.total;
        this.inquiryLoading = false;
      });
    },

    loadPage(page) {
      this.currentPage = page;
      this.getInquiries();
    },

    refresh() {
      this.collectionData.inquiries = [];
      this.getCollectionData();
      this.currentPage = 1;
      this.getInquiries();
    },

    deleteCollection(id) {
      const answer = prompt(
        "Delete this Collection ? Type 'delete' and choose OK to delete"
      );
      if (answer === "delete") {
        restAdapter.delete("/api/collection/" + id).then(() => {
          if (this.getIsAuthUser()) {
            notification.success("Collection deleted successfully");
          } else if (this.getIsSupplier()) {
            notification.success(
              "Collection delete request sent to account manager"
            );
          }
          this.$router.push({ name: "All Collections" });
        });
      } else if (answer !== null) {
        notification.error(
          "Please type the exact word 'delete'. Style was not deleted."
        );
      }
    },

    openInquiry() {
      this.$router.push({
        name: "Inquiry",
        params: { id: "new" },
        query: {
          collectionId: this.collectionId,
        },
      });
    },

    debounceSearch: debounce(function () {
      this.getInquiries();
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: $primaryDark !important;
  font-weight: 600 !important;
  text-decoration: none !important;
  background-color: #ffffff !important;
}
.v-tab:not(.v-tab--active) {
  color: $primaryDark !important;
  font-weight: 500 !important;
  opacity: 0.35 !important;
  text-decoration: none !important;
}

.v-tab:hover {
  background-color: #a89caa;
  cursor: pointer;
}

.style_card_container {
  display: flex;
  justify-items: center;
  margin-top: 25px;
  min-height: 400px;
  padding: 30px 30px;
}
.chat_card_container {
  margin: 0px;
  margin-top: 25px;
  min-height: 400px;
  padding: 30px 0;
}
.card-row {
  justify-content: start !important;
  margin-left: 10px;
}
.v-card {
  margin-top: 25px;
}
.createBtn-container {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.btn {
  height: 90px;
  width: auto;
  padding: 10px;
  font-size: 15px;
  margin-bottom: 10px;
  display: flex;
}
.btn:after {
  width: 0px !important;
}
.createButton {
  color: $tableBorder !important;
  text-decoration: none !important;
}
.miniInquiry {
  padding: 5px;
}
.button_card_items {
  display: flex;
  align-items: center;
  // width: 100%;
}
.button_card_icon {
  float: left;
  margin: 0 15px;
}
.button_icon .v-icon {
  font-size: 33px;
  color: $tableBorder !important;
}
.back_btn_class {
  width: 149px;
  height: 36px;
  margin: -5px 0px 20px -20px;
  background-color: rgba(71, 48, 104, 0.1) !important;
  border-radius: 81.2308px;
}
.back_btn_class:hover {
  cursor: pointer;
}
.text_color {
  justify-content: center !important;
  font-style: normal !important;
  font-weight: 600;
  font-size: 13.7378px;
  line-height: 116.1%;
  display: flex;
  align-items: center;
  color: $primaryDark !important;
}
.tabs {
  color: $light;
}
.tabs-items {
  background-color: $light;
  margin-bottom: 20px;
}
.tabs-slider {
  display: none;
}
.service_card_container {
  margin: 0px;
  margin-top: 25px;
  min-height: 400px;
  padding: 30px 0;
}
.service_buttons_container {
  padding-top: 50px;
}
.service_button_card {
  margin: 20px;
}
.subheading_color {
  background-color: $primaryDarkest;
  font-weight: 600;
  font-size: 16px;
}
.fieldset-subheader {
  padding-left: 0;
  font-size: 16px;
}
.form-label {
  text-align: right;
  color: $tableBorder;
}
.form-value {
  text-align: left;
  color: $primaryDark;
  font-weight: 600;
}
.router-link {
  color: $primaryDark !important;
}
.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}
.inquiry-container {
  justify-content: center;
  padding: 0px 10px;
}

.search-content {
  margin: 20px 0px 20px 0px;
  padding: 12px;
}

.inquiry-search-bar {
  text-align: right;
  justify-content: flex-start;
  background: #ffffff;
  box-shadow: 0px 3px 25px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
}

.search_type_container {
  margin-top: -10px !important;
  background-color: #f3edf7 !important;
  box-shadow: 3.19039px 14.8885px 12.7616px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
}

.search_field_class {
  margin: 0%;
}

.color_class {
  border: 1px solid #473068;
  color: #442d65;
}

.list_color.md-selected::v-deep .md-list-item-text {
  color: #473068 !important;
}

.list_color.md-theme-default.md-selected {
  background-color: #e2daed !important;
}

.md-field.md-theme-default.md-focused label {
  color: #442d65 !important;
}

.md-field.md-theme-default:before {
  background-color: #473068 !important;
}

.timeline_card_container {
  margin: 0px;
  margin-top: 25px;
  height: auto;
}

.error_message {
  padding: 200px;
  color: $danger;
  font-size: 18px;
}

.move-collection-title {
  height: 36px;
  margin: 0px 0px 21px 0px;
  background-color: rgb(231, 237, 237) !important;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 40px;
}

.title-text {
  display: flex;
  align-items: center;
  justify-content: center !important;
  font-weight: 600;
  font-size: 18px !important;
  padding: 10px;
  color: $primaryDark !important;
}

.supplier-listing {
  text-align: center;
}

.supplier-listing:hover {
  cursor: pointer;
  text-decoration: underline;
}

.brand-listing:hover {
  @extend .supplier-listing;
}
</style>
