<template>
  <v-col v-if="this.rooms.length" id="chat">
    <popup-window
      :active="showRenameRoomForm"
      :hasClose="false"
      noMargin
      @popup-close="resetForms"
      @popup-back="resetForms"
    >
      <div class="rename-room-form">
        <div class="rename-room-title">Rename room to:</div>
        <input
          v-model="newRoomName"
          class="rename-room-input"
          type="text"
          placeholder="Rename chat room subject/topic"
          id="search"
          name="search"
        />
        <div class="rename-room-buttons">
          <ui-button
            class="primary short space"
            @click="renameRoom"
            title="✔ Ok"
          />
          <ui-button class="lighter short" @click="resetForms" title="Cancel" />
        </div>
      </div>
    </popup-window>

    <popup-window
      :active="showInviteUserForm"
      title="Add room participants"
      @popup-close="resetForms"
      @popup-back="resetForms"
    >
      <div>
        <users-list-view
          :searchField="true"
          placeholder="Search business name"
          :membersList="nonSelectedList"
          :selectedList="selectedList"
          @add-item="addUserToSelectedList"
          @remove-item="removeUserFromSelectedList"
        />
        <md-progress-spinner
          v-if="creatingChat"
          md-mode="indeterminate"
        ></md-progress-spinner>
        <div class="createChatButton">
          <ui-button
            class="primary long"
            @click="inviteUser"
            :disabled="creatingChat"
            title="Add"
          />
        </div>
      </div>
    </popup-window>

    <popup-window
      :active="showChatParticipants"
      title="Chat participants"
      @popup-close="resetForms"
      @popup-back="resetForms"
    >
      <div>
        <chat-participants
          :searchField="true"
          placeholder="Search contact / business name"
          :membersList="
            this.currentRoom?.room?.roomId
              ? this.rooms.find(
                  (room) => room.roomId === this.currentRoom.room.roomId
                )?.users ?? []
              : []
          "
        />
        <md-progress-spinner
          v-if="creatingChat"
          md-mode="indeterminate"
        ></md-progress-spinner>
      </div>
    </popup-window>

    <popup-window
      :active="showRemoveUserForm"
      title="Remove room participants"
      @popup-close="resetForms"
      @popup-back="resetForms"
    >
      <div>
        <users-list-view
          :searchField="true"
          placeholder="Search business name"
          :membersList="nonSelectedList"
          :selectedList="selectedList"
          @add-item="addUserToSelectedList"
          @remove-item="removeUserFromSelectedList"
        />
        <md-progress-spinner
          v-if="creatingChat"
          md-mode="indeterminate"
        ></md-progress-spinner>
        <div class="createChatButton">
          <ui-button
            class="danger long"
            @click="removeUser"
            :disabled="creatingChat"
            title="Remove"
          />
        </div>
      </div>
    </popup-window>

    <popup-window
      :active="showCreateChatForm"
      title="Add room participants"
      @popup-close="resetForms"
      @popup-back="resetForms"
    >
      <div>
        <users-list-view
          :searchField="true"
          placeholder="Search business name"
          :membersList="nonSelectedList"
          :selectedList="selectedList"
          @add-item="addUserToSelectedList"
          @remove-item="removeUserFromSelectedList"
        />
        <md-progress-spinner
          v-if="creatingChat"
          md-mode="indeterminate"
        ></md-progress-spinner>
        <div class="createChatButton">
          <ui-button
            class="primary long"
            @click="createChat"
            :disabled="creatingChat"
            title="Create Chat"
          />
        </div>
      </div>
    </popup-window>

    <v-row class="search-messages">
      <v-col cols="6" offset="6" align="right">
        <div class="search-message-field">
          <v-icon class="search-icon">mdi-magnify</v-icon>
          <input
            v-model="searchMessage"
            class="search-input"
            type="text"
            placeholder="Search messages "
            id="search-message"
            name="search-message"
            v-on:input="searchChatMessages"
          />
        </div>
        <v-card
          v-if="searchMessage"
          class="mx-auto search-result"
          max-height="400"
          width="50%"
          tile
        >
          <div v-if="searching" class="spinner-box">
            <md-progress-bar md-mode="indeterminate"></md-progress-bar>
            <label>Getting matching messages</label>
          </div>
          <v-subheader v-if="matchingMessages.length !== 0 && !searching"
            >Select Message
          </v-subheader>
          <v-subheader v-else-if="!searching && matchingMessages.length === 0"
            >No matching messages found
          </v-subheader>
          <md-list v-if="!searching" class="md-double-line">
            <md-list-item
              v-for="message in this.matchingMessages"
              :key="message._id"
              @click="showSelectedMessage(message)"
            >
              <md-icon>search</md-icon>
              <div class="md-list-item-text">
                <span>{{ message.content }}</span>
                <span>{{
                  message.timestamp +
                  ", " +
                  message.date +
                  " by " +
                  message.username
                }}</span>
              </div>
            </md-list-item>
          </md-list>
        </v-card>
      </v-col>
    </v-row>

    <div v-if="scrolling" class="spinner-box">
      <md-progress-bar md-mode="indeterminate"></md-progress-bar>
      <label>Searching</label>
    </div>
    <div v-if="notFound" class="notFound-alert">Message Not Found</div>
    <chat-window
      :current-user-id="currentUserId"
      :rooms="rooms"
      :messages="messages"
      :rooms-loaded="roomsLoaded"
      :messages-loaded="messagesLoaded"
      @send-message="sendMessage"
      @fetch-messages="fetchMessages"
      :single-room="false"
      :show-reaction-emojis="false"
      :show-audio="false"
      :show-emojis="true"
      height="75vh"
      :messageActions="messageActions"
      :menuActions="menuActions"
      @message-action-handler="messageActionHandler"
      @menu-action-handler="menuActionHandler"
      @open-file="openFileHandler"
      @delete-message="deleteMessage"
      :accepted-files="acceptedFileTypes"
      :max-file-size="maxFileSize"
      :max-files-sum-size="maxFileSize"
      @limit-size-exceeded="limitSizeExceeded"
      @add-room="showCreateChat"
      :show-add-room="true"
      :room-id="startingRoomId"
      @show-reply-message="showReplyMessage"
    />
  </v-col>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import { ref } from "vue";
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import auth from "../auth";
import restAdapter from "../restAdapter";
import * as conf from "../conf.yml";
import notification from "../notification";
import utils from "../utils";
import debounce from "lodash/debounce";
import UsersListView from "../components/UsersListView.vue";
import ChatParticipants from "../components/ChatParticipants.vue";
import { isImageFile, isVedioFile, isAudioFile } from "../utils/media-type";
import routes from "../router/routes";

export default {
  name: "Chat",
  components: {
    ChatWindow,
    UsersListView,
    ChatParticipants,
  },

  setup(props) {
    let dataBus = ref(null);
    const routeName = props.inquiryId ? routes.INQUIRY : routes.COLLECTION;
    if (routeName === routes.INQUIRY) {
      dataBus = require("../utils/inquiryDataBus.js").inquiryDataBus;
    } else if (routeName === routes.COLLECTION) {
      dataBus = require("../utils/collectionDataBus.js").collectionDataBus;
    }

    return {
      dataBus,
    };
  },

  created() {
    if (this.isBusRoute()) {
      this.dataBus.$on("unpinned", this.unpinMessage);
      this.dataBus.$on("track-pin", this.showSelectedMessage);
    }
  },

  async mounted() {
    this.currentUserId = auth.user.id;
    await this.fetchRooms();
  },

  watch: {
    pinnedMessages: {
      handler: function (messages) {
        if (this.isBusRoute()) {
          this.dataBus.$emit("updated-pinned", messages);
        }
      },
    },
    activeTab: {
      handler: function (value) {
        this.updateSeen = value === "order" || value === "collection";
        this.sendSeenStatus(this.currentRoom.room.roomId);
      },
    },
    lastSeenMsg: {
      handler: function (value) {
        if (value && value.msgId) {
          this.updateSeenStatus(value.roomId, value.msgId);
        }
      },
    },
    currentRoom(currentRoom, previousRoom) {
      if (previousRoom && previousRoom.room) {
        window.Echo.leave(`chat.${previousRoom.room.roomId}`);
        window.Echo.leave(`deleteMessage.${previousRoom.room.roomId}`);
        window.Echo.leave(`seenMessage.${previousRoom.room.roomId}`);
      }
      window.Echo.private(`chat.${currentRoom.room.roomId}`).listen(
        ".privateMessageSent",
        (e) => {
          const recievedMessage = this.formatMessage(e.messageData);
          if (
            !this.messages
              .map((message) => message._id)
              .includes(recievedMessage._id)
          ) {
            auth.user.id !== recievedMessage.senderId &&
              this.messages.push(recievedMessage);
            e.messageData.fetchRooms && this.fetchRooms();
            auth.user.id !== recievedMessage.senderId &&
              this.sendSeenStatus(currentRoom.room.roomId, recievedMessage._id);
          }
        }
      );
      window.Echo.private(`deleteMessage.${currentRoom.room.roomId}`).listen(
        ".privateMessageDelete",
        (e) => {
          this.messages.forEach((msg) => {
            if (msg._id === e.messageData.message_id) {
              msg["deleted"] = true;
            }
          });
        }
      );

      window.Echo.private(`seenMessage.${currentRoom.room.roomId}`).listen(
        ".privateMessageSeen",
        (e) => {
          if (e.updateMultiple) {
            this.messages.forEach((msg) => {
              if (msg._id < e.lastSeenMsg) {
                msg["seen"] = true;
              }
            });
          } else {
            this.lastSeenMsg = e.lastSeenMsg && {
              roomId: currentRoom.room.roomId,
              msgId: e.lastSeenMsg,
            };
            if (!e.lastSeenMsg) {
              this.messages = this.messages.map((msg) => ({
                ...msg,
                seen: true,
              }));
            }
          }
        }
      );
    },
    messagesFetched: function (value) {
      if (value && this.searchingForMessage) {
        setTimeout(() => {
          this.scrollToMessage(this.searchingForMessage);
        }, 1000);
      }
    },
  },

  props: {
    collectionId: {
      type: Number,
      required: true,
    },
    inquiryId: {
      type: Number,
      default: null,
    },
    assignedSuppliers: {
      type: Array,
      default: () => [],
    },
    startingRoomId: {
      type: [Number, null],
    },
    activeTab: {
      type: String,
      default: "",
    },
    activeCard: {
      type: [Number, null],
    },
  },

  filters: {
    truncate: function (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
    },
  },

  methods: {
    isBusRoute() {
      return (
        this.$route.name === routes.INQUIRY ||
        this.$route.name === routes.COLLECTION
      );
    },

    isImage(file) {
      return isImageFile(file);
    },

    isVedio(file) {
      return isVedioFile(file);
    },

    isAudio(file) {
      return isAudioFile(file);
    },

    async fetchRooms() {
      this.roomsLoaded = false;
      const roomsResponse = await restAdapter.get(
        `/api/chats/${this.collectionId}`
      );

      this.roomsResponseData = roomsResponse.data.chat_list;

      roomsResponse.data = {
        ...roomsResponse.data,
        data: [
          ...roomsResponse.data.chat_list,
          ...roomsResponse.data.removed_chat_list,
        ],
      };

      const formatdata = [];

      if (roomsResponse.data && roomsResponse.data.data) {
        for (let i = 0; i < roomsResponse.data.data.length; i++) {
          const room = roomsResponse.data.data[i];

          const data = {
            roomId: room.id,
            unreadCount: 0,
            roomName:
              (room.chat_name || "") +
              (!room.chat_name && room.chat_type === 3
                ? room.members.map((member) => member.company_name).join(", ")
                : ""),
            collectionId: room.collection_id,
            roomType: room.chat_type,
            avatar: await this.getChatImage(room),
            removedRoomStatus: room.removed,
            users: await Promise.all(
              room.members.map(async (member) => {
                let avatar = null;
                try {
                  const avatarResponse = await restAdapter.get(`/api/user/image_show/${member.id}`);
                  avatar = avatarResponse.data.image;
                } catch (error) {
                  avatar = null;
                }
                return {
                  _id: member.id,
                  username: member.first_name + " " + member.last_name,
                  company: member.company_name,
                  avatar: avatar || this.person,
                };
              })
            ),
          };

          formatdata.push(data);
        }
      }

      this.rooms = formatdata;
      this.roomsLoaded = true;
      const chatStatus = this.rooms.length > 0;
      this.$emit("chat-status", chatStatus);

      this.rooms.forEach(async (room) => {
        room.unreadCount = await this.getCount(room.roomId);
      });
    },

    async getCount(room) {
      const response = await restAdapter
        .get(`/api/chats/${room}/unread_count`)
        .then((val) => val.data.unread_count);
      return response;
    },

    async fetchMessages(room) {
      this.messagesFetched = false;
      this.currentRoom = room;
      const currentRoomID = this.currentRoom.room.roomId;
      const isRoomReset = room.options && room.options.reset;

      this.rooms = this.rooms.map((data) => {
        if (data.roomId === room.room.roomId) {
          return {
            ...data,
            unreadCount: 0,
          };
        }
        return data;
      });

      this.rooms.forEach((roomy) => {
        if (roomy.roomId === room.room.roomId) {
          window.Echo.leave(
            `chatCount.${room.room.roomId}.${this.currentUserId}`
          );
        }

        if (roomy.roomId !== room.room.roomId) {
          window.Echo.private(
            `chatCount.${roomy.roomId}.${this.currentUserId}`
          ).listen(".unreadMessageCount", (e) => {
            roomy["unreadCount"] = e.data.original.unread_count;
          });
        }
      });

      this.refreshPinned(isRoomReset, room);

      if (this.$route.query && currentRoomID != this.$route.query.room_id) {
        this.ignoreQuery = true;
      }

      if (
        !this.ignoreQuery &&
        isRoomReset &&
        this.$route.query &&
        this.messageIdFromQuery === this.$route.query.msg &&
        currentRoomID == this.$route.query.room_id
      ) {
        this.messageIdFromQuery = null;
        return;
      }

      this.messageIdFromQuery = this.$route.query?.msg;
      this.$emit("chat-id", currentRoomID);

      this.resetForms();

      // if message id is in query, show that message
      if (this.$route.query.msg && this.initialFetch) {
        this.initialFetch = false;
        this.pagination = 0;
        this.matchingMessages = [];
        this.messages = [];
        this.messagesLoaded = false;
        this.pagination++;
        await this.showSelectedMessage({ _id: this.$route.query.msg }, room);
        window.history.pushState({}, "", window.location.href.split("?")[0]);
        return;
      }

      if (isRoomReset) {
        // reset everything
        this.pagination = 0;
        this.matchingMessages = [];
        this.messages = [];
        this.messagesLoaded = false;
      }

      // get next page
      this.pagination++;

      // get next messages and push them
      const messagesResponse = await restAdapter.get(
        `/api/chats/${room.room.roomId}/view_chat?page=${this.pagination}`
      );
      const formattedMessages = messagesResponse.data.data.map((message) =>
        this.formatMessage(message)
      );

      if (this.currentRoom.room.roomId === room.room.roomId) {
        this.messages.unshift(...formattedMessages);
      }

      // if last page set messages loaded
      if (!messagesResponse.data.data.length) {
        this.messagesLoaded = true;
      }

      this.messagesFetched = true;
    },

    async refreshPinned(isRoomReset, room) {
      if (!isRoomReset) return;

      // get pinned
      const messagesResponse = await restAdapter.get(
        `/api/chats/${room.room.roomId}/pin_list `
      );

      const pinnedMessagesResponse = messagesResponse.data.data.map((data) => {
        return {
          ...data,
          message: data.message.map((message) => {
            const fileName = data.file?.title.split(".")[0];
            const pinnedBy = data.pinned_by;
            return {
              ...message,
              attachment_name: fileName,
              pinned_by: pinnedBy,
            };
          }),
        };
      });

      const formattedPinnedMessages = pinnedMessagesResponse.map((message) =>
        this.formatMessage(message.message[0], true)
      );

      this.pinnedMessages = formattedPinnedMessages;
    },

    async sendMessage(data) {
      const formData = new FormData();
      formData.append("collection_id", this.collectionId);
      formData.append("message", data.content);

      if (data.replyMessage && data.replyMessage._id) {
        const replyMessageId = data.replyMessage._id;
        formData.append("reply_id", replyMessageId);
      }

      if (data.file && data.file.blob) {
        if (data.file.size > this.maxFileSize) {
          notification.error(
            `${data.file.name}.${data.file.extension} is too large to send`
          );
          return;
        }
        const file = new File(
          [data.file.blob],
          data.file.name + "." + data.file.extension,
          {
            type: data.file.type,
            lastModified: new Date().getTime(),
          }
        );
        formData.append("file", file);
      }

      this.loadingMsgId = this.loadingMsgId - 1;
      const loadingId = this.loadingMsgId;

      const returnedMessage = await restAdapter
        .post(`/api/chats/${data.roomId}/create_message`, formData, {
          onUploadProgress: (progressEvent) => {
            if (data.file && data.file.blob) {
              this.messages = this.messages.filter(
                (msg) => msg._id != loadingId
              );
              const loadingScore = Math.floor(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              const loadingMessage = {
                _id: loadingId,
                senderId: auth.user.id || 0,
                date: this.$moment().format("D MMM"),
                timestamp: this.$moment().format("HH:mm"),
                content: data.content,
                seen: false,
                uploading: true,
                uploadingScore: loadingScore,
                fileId: data.file.name + "." + data.file.extension,
                pageId: this.pagination,
              };

              this.messages.push(loadingMessage);
            }
          },
        })
        .catch((error) => {
          if (data.file && data.file.blob) {
            notification.error(
              `${data.file.name + "." + data.file.extension} failed to upload`
            );
          }
        });

      this.messages = this.messages.filter((msg) => msg._id != loadingId);
      const formattedMessage = this.formatMessage(returnedMessage.data.data);
      this.messages.push(formattedMessage);
      this.updateSeenStatus(
        data.roomId,
        this.lastSeenMsg && this.lastSeenMsg.msgId
      );
      this.lastSeenMsg = null;

      if (returnedMessage.data.data.attachment_id) {
        this.$emit("file-upload-status", 1);
      }
    },

    showReplyMessage(message) {
      this.showSelectedMessage(message);
    },

    async messageActionHandler({ roomId, action, message }) {
      let isForAll = false;
      switch (action.name) {
        case "pinMessage":
          isForAll = false;
          break;
        case "pinForAll":
          isForAll = true;
          break;
      }

      if (!this.pinnedMessages.find((msg) => msg._id === message._id)) {
        this.pinnedMessages.push({
          ...this.messages.find((msg) => msg._id === message._id),
          canUnpin: true,
        });

        await restAdapter.post(`/api/chat/messages/${message._id}/pin`, {
          is_for_all: isForAll,
        });
      }
    },

    async menuActionHandler({ roomId, action }) {
      switch (action.name) {
        case "inviteUser": {
          this.resetForms();
          const membersListData = await restAdapter.get(
            `/api/chats/${this.collectionId}/view_members?chat_id=${this.currentRoom.room.roomId}`
          );
          this.membersList = membersListData.data.member_list;
          this.membersList = this.membersList.map((member) => {
            return {
              ...member,
              avatar: this.assignedSuppliers.find(
                (supplier) => supplier.id === member.id
              ).avatar,
            };
          });
          if (!this.membersList.length) {
            notification.error("There are no companies available to add.");
            break;
          }
          this.showInviteUserForm = true;
          break;
        }
        case "viewParticipants": {
          this.resetForms();
          this.showChatParticipants = true;
          break;
        }
        case "removeUser": {
          this.resetForms();
          const membersListData = await restAdapter.get(
            `/api/chats/${this.collectionId}/view_members_remove?chat_id=${this.currentRoom.room.roomId}`
          );

          this.membersList = membersListData.data.member_list.filter(
            (member) => {
              return member.id !== auth.user.account.organization_registry_id;
            }
          );

          this.membersList = this.membersList.map((member) => {
            return {
              ...member,
              avatar: this.assignedSuppliers.find(
                (supplier) => supplier.id === member.id
              ).avatar,
            };
          });
          if (!this.membersList.length) {
            notification.error("There are no companies available to remove.");
            break;
          }
          this.showRemoveUserForm = true;
          break;
        }
        case "renameRoom": {
          this.showRenameRoomForm = true;
          this.renamingRoomId = roomId;
          break;
        }
      }
      this.fetchRooms();
    },

    async deleteMessage({ roomId, message }) {
      await restAdapter.delete(`/api/chat/messages/${message._id}/delete`);
      message.fileId && this.$emit("refresh-attachments");
    },

    async unpinMessage(messageId) {
      await restAdapter.delete(`/api/chat/messages/${messageId}/unpin`);
      this.pinnedMessages = this.pinnedMessages.filter(
        (msg) => msg._id !== messageId
      );
    },

    showCreateChat() {
      this.resetForms();

      this.membersList = this.assignedSuppliers
        .filter(
          (supplier) =>
            supplier.id !== auth.user.account.organization_registry.id
        )
        .map((item) => ({ ...item }));

      if (!this.membersList.length) {
        notification.error(
          "There are no companies available to create one to one chat with."
        );
        return;
      }

      this.showCreateChatForm = true;
    },

    async createChat() {
      this.creatingChat = true;
      const createChatUserId = this.selectedUsersIds[0];

      if (!this.selectedUsersIds.length) {
        notification.error("Please select a member to create the chat");
        this.creatingChat = false;
        return;
      } else {
        const inviteChatUserIds = this.selectedUsersIds.slice(1);
        const newChatResponse = await restAdapter.post(
          `/api/collections/${this.collectionId}/create_chat`,
          {
            member_id: createChatUserId,
          }
        );
        const newChatRoomId = newChatResponse.data.chat.id;
        if (inviteChatUserIds.length) {
          for (const userId of inviteChatUserIds) {
            await restAdapter.post(`/api/chats/${newChatRoomId}/add_member`, {
              member_id: userId,
            });
          }
        }
        notification.success("Chat successfully created");
        this.creatingChat = false;
        this.fetchRooms();
        this.resetForms();
      }
    },

    openFileHandler({ message, action }) {
      switch (action) {
        case "preview":
          window.open(
            `${conf.server.host}/api/inquiries/file_show/${message.fileId}`
          );
          break;
        case "download":
          window.open(
            `${conf.server.host}/api/inquiries/file_download/${message.fileId}`
          );
          break;
        // case "preview": {
        //   const image = new Image();
        //   image.src = message.file.url;
        //   image.style = 'display:block; margin:auto;';
        //   const w = window.open("about:blank");
        //   w.document.write(image.outerHTML);
        //   w.document.close();
        //   break;
        // }

        // case "download": {
        //   const imageUrl = message.file.url;
        //   const downloadImage = document.createElement("a");
        //   downloadImage.href = imageUrl;
        //   downloadImage.download = message.file.fileName;
        //   downloadImage.click();
        //   break;
        // }
      }
    },

    formatMessage(message, isPinMessage = false) {
      const formattedMessage = {
        _id: message.id,
        senderId: message.created_by || 0,
        username: message.first_name
          ? message.first_name + " - " + message.company
          : "🔔",
        content: message.message || "",
        date: this.$moment(message.created_at).format("D MMM"),
        name: message.attachment_name,
        timestamp: this.$moment(message.created_at).format("HH:mm"),
        deleted: message.deleted_at && message.deleted_at !== null,
        fileId: message.attachment_id,
        seen: message.seen,
        pageId: this.pagination,
        ...(isPinMessage
          ? { canUnpin: message.pinned_by == auth.user.id }
          : {}),
      };

      if (message.reply_id) {
        const replyTo = message.reply_to;
        const reply = {
          _id: message.reply_id,
          content: (replyTo && replyTo.message) || "",
        };
        if (replyTo && replyTo.attachment_id) {
          reply.file = {
            type: replyTo.mime_type,
            name: replyTo.title,
            extension:
              replyTo.attachment_id && replyTo.attachment_id.split(".").pop(),
            url: `${conf.server.host}/api/inquiries/file_show/${replyTo.attachment_id}`,
          };
        }

        formattedMessage.replyMessage = reply;
      }
      if (message.attachment_id) {
        if (message.mime_type) {
          formattedMessage.file = {
            name: message.attachment_name,
            extension: message.attachment_id.split(".").pop(),
            type: message.mime_type
              ? message.mime_type
              : message.file_mime_type,
            url: `${conf.server.host}/api/inquiries/file_show/${message.attachment_id}`,
            // url: message.attachment_url,
            fileName: message.attachment_id,
          };
        } else {
          formattedMessage.deleted = true;
        }
      }
      return formattedMessage;
    },

    toggleShowPinned() {
      this.showPinned = !this.showPinned;
    },

    async inviteUser() {
      this.creatingChat = true;
      if (!this.selectedUsersIds.length) {
        notification.error("Please select a company to add to the chat");
        this.creatingChat = false;
        return;
      } else {
        for (const userId of this.selectedUsersIds) {
          await restAdapter.post(
            `/api/chats/${this.currentRoom.room.roomId}/add_member`,
            {
              member_id: userId,
            }
          );
        }
        this.fetchRooms();
        this.resetForms();
        notification.success("Company successfully added");
        this.creatingChat = false;
      }
    },

    async removeUser() {
      this.creatingChat = true;

      if (!this.selectedUsersIds.length) {
        notification.error("Please select a company to remove from the chat");
        this.creatingChat = false;
        return;
      } else {
        for (const userId of this.selectedUsersIds) {
          await restAdapter.delete(
            `/api/chats/${this.currentRoom.room.roomId}/remove_member`,
            {
              member_id: userId,
            }
          );
        }
        this.fetchRooms();
        this.resetForms();
        notification.success("Company successfully removed");
        this.creatingChat = false;
      }
    },

    async renameRoom() {
      if (!this.newRoomName) {
        return;
      }
      restAdapter
        .put(`/api/chats/${this.renamingRoomId}/edit`, {
          name: this.newRoomName,
        })
        .then(() => {
          this.resetForms();
          this.fetchRooms();
        });
    },

    resetForms() {
      this.showInviteUserForm = false;
      this.showChatParticipants = false;
      this.showRemoveUserForm = false;
      this.showCreateChatForm = false;
      this.showRenameRoomForm = false;

      this.selectedUsersIds = [];
      this.selectedList = [];
      this.newRoomName = "";
      this.renamingRoomId = null;
    },

    async getChatImage(room) {
      if (
        room.chat_type === 1 ||
        (room.chat_type === 3 && room.members.length <= 2)
      ) {
        return this.person;
      } else if (room.chat_type === 3 && room.members.length > 2) {
        return this.groupChatImage;
      } else {
        return this.commonChatImage;
      }
    },

    debounceSearch: debounce(async function () {
      const messagesResponse = await restAdapter.get(
        `/api/chats/${this.currentRoom.room.roomId}/find_messages?key=${this.searchMessage}`
      );
      this.matchingMessages = await messagesResponse.data.data
        .filter((message) => !message.deleted_at)
        .map((message) => this.formatMessage(message));
      this.searching = false;
    }, 1000),

    async searchChatMessages() {
      if (this.searchMessage) {
        this.searching = true;
        await this.debounceSearch();
      } else {
        this.matchingMessages = [];
      }
    },

    async showSelectedMessage(message, room = null) {
      this.scrolling = true;
      this.searchMessage = null;
      this.messagesLoaded = false;
      this.showPinned = false;
      let messagesResponse;
      try {
        this.messagesFetched = false;
        messagesResponse = await restAdapter.get(
          `/api/chats/messages/${message._id}/search`
        );
        this.messagesFetched = true;
      } catch (error) {
        this.messagesFetched = true;
        if (room) {
          if (!room.options) {
            room.options = {};
          }
          room.options.reset = true;
          this.notFound = true;
          this.scrolling = false;
          this.fetchMessages(room);
          setTimeout(() => {
            this.notFound = false;
          }, 2000);
        }
        return null;
      }

      if (messagesResponse.data.no_record) {
        this.notFound = true;
        this.messagesLoaded = true;
        this.scrolling = false;
        this.fetchMessages(room);
        setTimeout(() => {
          this.notFound = false;
        }, 2000);
        return null;
      }

      this.messages = [];
      this.matchingMessages = [];

      const formattedMessages = await messagesResponse.data.messages.map(
        (message) => this.formatMessage(message)
      );
      if (!messagesResponse.data.messages.length) {
        this.messagesLoaded = true;
      }
      this.messages = formattedMessages;
      this.pagination = messagesResponse.data.page;

      this.searchingForMessage = message;
      setTimeout(() => {
        this.scrollToMessage(message);
      }, 1000);
    },
    limitSizeExceeded() {
      notification.error("Files size limit exceeded");
    },
    addUserToSelectedList(user) {
      this.selectedUsersIds.push(user.id);
      this.selectedList.push(user);
    },
    removeUserFromSelectedList(user) {
      this.selectedList = this.selectedList.filter(
        (selected) => selected.id !== user.id
      );
      this.selectedUsersIds = this.selectedUsersIds.filter(
        (id) => id !== user.id
      );
    },
    async sendSeenStatus(roomId, lastSeenMsg = null) {
      if (
        this.updateSeen &&
        this.currentRoom.room.roomId === roomId &&
        ((this.$route.name == routes.INQUIRY &&
          this.$route.params.id == this.inquiryId) ||
          (this.$route.name == routes.COLLECTION &&
            this.$route.params.id == this.collectionId) ||
          (this.activeCard && this.activeCard == this.collectionId))
      ) {
        await restAdapter.put(`/api/chats/${roomId}/update_seen`, {
          lastSeenMsg: lastSeenMsg,
        });
      }
    },
    updateSeenStatus(roomId, lastSeenMsg = null) {
      if (this.currentRoom.room.roomId === roomId) {
        if (lastSeenMsg) {
          this.messages.forEach((msg) => {
            if (msg._id === lastSeenMsg) {
              msg["seen"] = true;
            }
          });
        }
      }
    },
    scrollToMessage(message) {
      if (!this.messagesFetched) {
        return;
      }
      this.searchingForMessage = false;
      document.getElementById(message._id).scrollIntoView();
      document.getElementById(message._id).style.backgroundColor =
        "rgba(0, 0, 0, 0.3)";
      this.scrolling = false;
      setTimeout(() => {
        document.getElementById(message._id).style.backgroundColor = null;
      }, 6000);
    },
  },

  data: () => ({
    messageIdFromQuery: null,
    ignoreQuery: false,
    loadingMsgId: 0,
    commonChatImage: require(`../assets/chat/common_chat.png`),
    groupChatImage: require(`../assets/chat/group_chat.png`),
    person: require(`../assets/chat/person.png`),
    notFound: false,
    roomsLoaded: false,
    messagesLoaded: false,
    rooms: [],
    count: [],
    roomsResponseData: [],
    messages: [],
    pinnedMessages: [],
    currentUserId: 0,
    currentRoom: {},
    showPinned: false,
    pagination: 1,
    searching: false,
    scrolling: false,
    lastSeenMsg: null,
    updateSeen: true,
    // fetchMessagesInterval: null,
    // lastFetched: null,
    showInviteUserForm: false,
    showRemoveUserForm: false,
    showRenameRoomForm: false,
    showChatParticipants: false,
    membersList: [],
    showCreateChatForm: false,
    selectedList: [],
    selectedUsersIds: [],
    newRoomName: "",
    renamingRoomId: null,
    acceptedFileTypes: utils.acceptedFileTypes,
    searchMessage: null,
    matchingMessages: [],
    initialFetch: true,
    maxFileSize: 50 * 1024 * 1024,
    creatingChat: false,
    showSendMessage: true,
    searchingForMessage: false,
    messagesFetched: false,
    messageActions: [
      {
        name: "replyMessage",
        title: "Reply",
      },
      {
        name: "deleteMessage",
        title: "Delete Message",
        onlyMe: true,
      },
      {
        name: "pinMessage",
        title: "Pin Message",
      },
    ],
  }),
  computed: {
    menuActions() {
      const actions = [];

      if (
        this.currentRoom &&
        this.currentRoom.room &&
        this.currentRoom.room.roomType === 3
      ) {
        actions.push({
          name: "inviteUser",
          title: "Add Company",
        });
        actions.push({
          name: "removeUser",
          title: "Remove Company",
        });
      }

      actions.push(
        {
          name: "viewParticipants",
          title: "View Participants",
        },
        {
          name: "renameRoom",
          title: "Rename Room",
        }
      );

      return actions;
    },
    nonSelectedList() {
      let nonSelected = [];
      nonSelected = this.membersList.filter((user) => {
        return !this.selectedList.includes(user);
      });
      return nonSelected;
    },
  },
};
</script>

<style lang="scss" scoped>
#chat {
  text-align: left;
}

.search-result {
  position: absolute;
  margin: 0;
  padding: 5px;
  z-index: 99;
}

.md-double-line {
  overflow-y: scroll;
  max-height: 300px;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.spinner-box {
  position: relative;
}

.notFound-alert {
  color: red;
}

.pin_messages {
  cursor: pointer;
}

.pinned-messages {
  display: inline-block;
}

.centered-and-cropped {
  object-fit: cover;
  height: inherit;
  width: inherit;
}

.card-media-size {
  height: 50px;
  width: 100%;
}

.container {
  overflow-y: scroll;
  max-height: 220px;
  position: absolute;
  width: 74.5%;
  z-index: 99;
}

.message_content {
  padding: 10px;
  height: 75px;
  cursor: pointer;
}

.unpin_star {
  height: 35px;
}

.chat-container {
  padding: 15px;
  height: 180px;
  position: absolute;
  width: 74.5%;
  z-index: 99;
}

.pinned-non-preview-container {
  background: #e5e5e6;
}

.pinned-non-preview-icon {
  color: #1976d2;
}

.pinned-non-preview-text {
  white-space: nowrap;
  overflow: hidden;
  margin: 0px;
  line-height: normal;
  color: #6e6e6e;
  font-size: 12px;
}

.createChatButton {
  padding: 30px;
}

.rename-room-form {
  width: 70%;
  margin: auto;
}

.rename-room-title {
  text-align: left;
  color: $primaryDark;
  font-weight: 600;
  font-size: 16px;
  padding: 30px 0 15px 0;
}

.rename-room-input[type="text"],
select {
  height: 40px;
  width: 100%;
  border: none;
  border-bottom: 1.5px solid $primaryDark;
  color: $spinnerStroke;
}

input:focus {
  outline: none;
}

::placeholder {
  color: $primaryDark;
  font-weight: 400;
}

.rename-room-buttons {
  padding: 30px 0 15px 0;
}

.search-message-field {
  display: flex;
  width: 158px;
  height: 32px;
  background: $paginateText;
  border-radius: 37px;
  align-items: center;
  padding: 0px 8px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.search-icon {
  color: $primaryDarkest;
}

.search-input {
  margin-left: 5px;
}

.search-input[type="text"],
select {
  height: 40px;
  width: 100%;
  border: none;
  color: $spinnerStroke;
}
</style>
