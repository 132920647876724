<template>
  <div class="attachments-container" :style="cssProps">
    <div class="sidebar-toggle-buttons">
      <div
        id="tgl-chat"
        :class="`sidebar-toggle ${
          activeToggleId === 'tgl-chat' ? 'toggle-active' : ''
        }`"
        @click="toggle($event)"
      >
        Chat
      </div>
      <div
        id="tgl-common"
        :class="`sidebar-toggle ${
          activeToggleId == 'tgl-common' ? 'toggle-active' : ''
        }`"
        @click="toggle($event)"
      >
        Common
      </div>
      <div
        id="tgl-pinned"
        :class="`sidebar-toggle ${
          activeToggleId == 'tgl-pinned' ? 'toggle-active' : ''
        }`"
        @click="toggle($event)"
      >
        Pinned
      </div>
    </div>
    <file-upload
      v-if="activeToggleId == 'tgl-common' && isBrandOrCreator"
      :collectionId="collectionId"
      @file-uploaded="refreshFiles"
    />
    <div class="files-container">
      <v-container v-if="activeToggleId != 'tgl-pinned'">
        <v-row v-if="!filesToShow.length" class="info-message">
          <div>No files to show</div>
        </v-row>
        <v-row no-gutters>
          <v-col
            no-gutters
            v-for="file in filesToShow"
            :key="file.id"
            align="center"
            class="image-container-col"
          >
            <div
              v-if="isImage(file)"
              class="image-container"
              :style="`background-image: url(${`${serverHost}/api/inquiries/file_show/${file.upload_name}`});`"
              @click="
                handlePreview(
                  `${serverHost}/api/inquiries/file_show/${file.upload_name}`
                )
              "
            >
              <div class="more-vert" @click="openActions(file.id, $event)">
                <v-icon class="more-vert-icon">more_vert</v-icon>
              </div>
              <div v-if="file.id === activeActionsId" class="actions-container">
                <div class="actions-list" @click="download(file, $event)">
                  Download
                </div>
                <div
                  v-if="
                    isSupportedThumbnail(file.upload_name) && isBrandOrCreator
                  "
                  class="actions-list"
                  @click="setDefault(file, $event)"
                >
                  Set Default
                </div>
                <!-- Copy to style file is not implemented yet -->
                <!-- <div
                  class="actions-list"
                  @click="activateInquiryWindow(file, $event)"
                >
                  Copy To
                </div> -->
                <div
                  v-if="file.user_id === userId || isBrandOrCreator"
                  class="actions-list"
                  @click="deleteFile(file, $event)"
                >
                  Delete
                </div>
              </div>
              <center class="non-preview-content-container">
                <center class="non-preview-file-title">
                  <truncate-hovering :text="file.title" :length="11" />
                </center>
                <center class="pinned-non-preview-text">
                  {{ file.title.split(".").pop() }}
                </center>
              </center>
            </div>
            <div v-else class="non-preview-container">
              <div
                class="more-vert more-vert-enhanced"
                @click="openActions(file.id, $event)"
              >
                <v-icon class="more-vert-icon">more_vert</v-icon>
              </div>
              <div v-if="file.id === activeActionsId" class="actions-container">
                <div class="actions-list" @click="download(file, $event)">
                  Download
                </div>
                <div
                  v-if="
                    isSupportedThumbnail(file.upload_name) && isBrandOrCreator
                  "
                  class="actions-list"
                  @click="setDefault(file, $event)"
                >
                  Set Default
                </div>
                <!-- Copy to style file is not implemented yet -->
                <!-- <div
                  class="actions-list"
                  @click="activateInquiryWindow(file, $event)"
                >
                  Copy To
                </div> -->
                <div
                  v-if="file.user_id === userId || isBrandOrCreator"
                  class="actions-list"
                  @click="deleteFile(file, $event)"
                >
                  Delete
                </div>
              </div>
              <center>
                <v-icon class="pinned-non-preview-icon">article</v-icon>
              </center>
              <center class="non-preview-content-container">
                <center class="non-preview-file-title">
                  <truncate-hovering :text="file.title" :length="11" />
                </center>
                <center class="pinned-non-preview-text">
                  {{ file.title.split(".").pop() }}
                </center>
              </center>
            </div>
            <div class="date-show">
              {{ $moment(file.created_at).format("HH:mm, D M Y") }}
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row class="info-message">
          <div v-if="pinned.length">
            Showing a total of {{ pinned.length }} pinned messages.
          </div>
          <div v-else>No pinned messages</div>
        </v-row>
        <v-row no-gutters>
          <v-col
            no-gutters
            v-for="message in pinned"
            :key="message._id"
            align="center"
            class="image-container-col"
          >
            <div
              class="pinned-container"
              :style="`width: ${
                message.content ? 'max-content' : 'fit-content'
              };`"
              @click="trackPin(message)"
            >
              <div v-if="message.file">
                <div
                  v-if="isImage(message.file)"
                  class="image-container"
                  :style="`background-image: url(${message.file.url});`"
                >
                  <div
                    v-if="!message.content && message.canUnpin"
                    class="more-vert more-vert-enhanced"
                    @click="unpinPinned(message._id, $event)"
                  >
                    <v-icon class="more-vert-icon star-icon">star_rate</v-icon>
                  </div>
                  <center class="non-preview-content-container">
                    <center class="non-preview-file-title">
                      <truncate-hovering
                        :text="message.file.name"
                        :length="11"
                      />
                    </center>
                    <center class="pinned-non-preview-text">
                      {{ message.file.extension }}
                    </center>
                  </center>
                </div>
                <div v-else>
                  <div class="non-preview-container">
                    <div
                      v-if="!message.content && message.canUnpin"
                      class="more-vert more-vert-enhanced"
                      @click="unpinPinned(message._id, $event)"
                    >
                      <v-icon class="more-vert-icon star-icon"
                        >star_rate</v-icon
                      >
                    </div>
                    <center>
                      <v-icon class="pinned-non-preview-icon">article</v-icon>
                    </center>
                    <center class="pinned-text-container">
                      <center class="pinned-file-name">
                        {{ message.file.name }}
                      </center>
                      <center class="pinned-non-preview-text">
                        {{ message.file.extension }}
                      </center>
                    </center>
                  </div>
                </div>
              </div>
              <div
                v-if="message.content"
                class="pinned-content-container"
                :style="`width: ${!message.file ? '170px' : '95px'};`"
              >
                <div style="position: relative">
                  <div
                    class="more-vert more-vert-enhanced"
                    v-if="message.canUnpin"
                    @click="unpinPinned(message._id, $event)"
                  >
                    <v-icon class="more-vert-icon star-icon">star_rate</v-icon>
                  </div>
                  <div class="pinned-content">{{ message.content }}</div>
                </div>
              </div>
            </div>
            <div class="date-show">
              {{ $moment(message.created_at).format("HH:mm, D M Y") }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <popup-window
      :active="showPreview"
      :fullScreen="true"
      maxWidth="fit-content"
      width="fit-content"
      @popup-close="handlePreview()"
      @popup-back="handlePreview()"
    >
      <div>
        <img :src="previewingFile" style="max-width: 500px" />
      </div>
    </popup-window>
    <div class="popup-container">
      <v-dialog persistent v-model="inquiryWindow" width="80%">
        <v-card width="100%">
          <SelectInquiry
            :active="inquiryWindow"
            :inquiries="inquiries"
            :file="selectedFile"
            @close="closePopUp"
          />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import FileUpload from "../FileUpload.vue";
import PopupWindow from "../base/PopupWindow.vue";
// import restAdapter from "../../restAdapter";
import utils from "../../utils";
import { isImageFile } from "../../utils/media-type";
import { collectionDataBus } from "../../utils/collectionDataBus";
import * as conf from "../../conf.yml";
import SelectInquiry from "../collection/SelectInquiry.vue";

export default {
  name: "CollectionSideBar",
  components: {
    FileUpload,
    PopupWindow,
    SelectInquiry,
  },
  created() {
    collectionDataBus.$on("updated-pinned", this.updatePinned);
  },
  props: {
    collectionId: {
      type: [Number, String],
      required: true,
    },
    files: {
      type: Array,
      default: () => [],
    },
    inquiries: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: [Number, String],
      required: true,
    },
    isBrandOrCreator: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    serverHost() {
      return conf.default.server.host;
    },
    cssProps() {
      const height = this.activeToggleId === "tgl-common" ? "100px" : "45px";
      return {
        "--elements-height": height,
      };
    },
    filesToShow() {
      switch (this.activeToggleId) {
        case "tgl-chat":
          return this.files.filter((file) => file.chat_file);

        case "tgl-common":
          return this.files.filter((file) => !file.chat_file);

        default:
          return this.files;
      }
    },
  },
  data: () => ({
    selectedFile: null,
    inquiryWindow: false,
    activeToggleId: "tgl-common",
    activeActionsId: "",
    pinned: [],
    showPreview: false,
    previewingFile: "",
    acceptedThumbnailTypes: utils.acceptedThumbnailTypes,
  }),
  methods: {
    closePopUp() {
      this.selectedFile = null;
      this.inquiryWindow = false;
      this.activeActionsId = "";
    },
    activateInquiryWindow(file, event) {
      event.stopPropagation();
      this.inquiryWindow = true;
      this.selectedFile = file;
    },
    toggle(e) {
      this.activeToggleId = e.target.id;
    },
    openActions(id, event) {
      event.stopPropagation();
      if (this.activeActionsId === id) {
        this.activeActionsId = "";
      } else {
        this.activeActionsId = id;
      }
    },
    handlePreview(src) {
      if (src) {
        this.showPreview = true;
        this.previewingFile = src;
      } else {
        this.showPreview = false;
        this.previewingFile = "";
      }
    },
    isImage(file) {
      return isImageFile(file);
    },
    getFileExtension(filename) {
      return (
        filename.match(/\.[0-9a-z]+$/i) &&
        filename.match(/\.[0-9a-z]+$/i)[0].toLowerCase()
      );
    },
    isSupportedThumbnail(filename) {
      const supportedThumbnailTypes = this.acceptedThumbnailTypes.split(",");
      return supportedThumbnailTypes.includes(this.getFileExtension(filename));
    },
    updatePinned(messages) {
      this.pinned = messages;
    },
    unpinPinned(messageId, event) {
      event.stopPropagation();
      collectionDataBus.$emit("unpinned", messageId);
    },
    trackPin(message) {
      collectionDataBus.$emit("track-pin", message);
    },
    refreshFiles() {
      this.$emit("file-uploaded");
    },
    deleteFile(file, event) {
      event.stopPropagation();
      this.$emit("delete", file.upload_name);
    },
    setDefault(file, event) {
      event.stopPropagation();
      this.$emit("set-default", file.upload_name);
    },
    download(file, event) {
      event.stopPropagation();
      this.$emit("download", file);
    },
  },
};
</script>

<style lang="scss" scoped>
.attachments-container {
  position: relative;
  background: $paginateText;
  padding: 15px 0px;
  height: calc(75vh + 32px + 15px);
}

.sidebar-toggle-buttons {
  display: flex;
  margin: auto;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  background: $paginateBackground;
  border: 0.5px solid $primaryDark;
  border-radius: 73.913px;
  padding: 0px 3px;
  margin-bottom: 15px;
}

.sidebar-toggle {
  width: 33.33%;
  margin: 3px 0px;
  color: $primaryDark;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.sidebar-toggle:hover {
  font-weight: 1000;
}

.toggle-active {
  background: $paginateText;
  border: 0.5px solid $primaryDark;
  box-shadow: 1px 3.92508px 6px rgba(91, 61, 135, 0.19);
  border-radius: 73.913px;
}

.toggle-active:hover {
  font-weight: 600;
}

.file-upload-container {
  width: 90%;
  height: 40px;
  margin: auto;
  background: rgba(245, 238, 255, 0.47);
  border: 0.5px dashed $primaryDarkest;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.file-upload-button {
  z-index: 10;
  margin-left: 5px;
  width: 30%;
  background: $fileUpload;
  border: 0.5px solid $primaryDark;
  border-radius: 3px;
  color: $primaryDarkest;
  font-weight: 400;
  cursor: pointer;
}

.file-upload-button:hover {
  box-shadow: 0 0.2px 0.3px -1px $primaryDarkest,
    0 0.2px 0.3px 1px $primaryDarkest, 0 0.2px 0.3px 0.2px $primaryDarkest,
    0 0.2px 0.3px 0.2px $primaryDarkest;
}

.file-upload-browse {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  color: $primaryDarkest;
  font-weight: 600;
}

.files-container {
  background: rgba(245, 238, 255, 0.47);
  height: calc(100% - var(--elements-height));
  width: 90%;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 25px;
  overflow-y: auto;
}

.image-container-col {
  justify-content: center;
  padding: 15px !important;
  flex-grow: 0;
}

.info-message {
  justify-content: center;
  padding: 10px !important;
  color: $infoMessage;
}

.image-container {
  position: relative;
  width: 75px;
  height: 75px;
  background-size: 100%;
  border: 0.5px solid $primaryDark;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.more-vert {
  background: $paginateText;
  width: 16px;
  height: 16px;
  float: right;
  border-radius: 50%;
  cursor: pointer;
}

.more-vert:hover {
  border: 0.5px solid $primaryDark;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.more-vert-enhanced {
  position: absolute;
  right: 0;
  top: 0;
}

.more-vert-icon {
  font-size: 15px;
  vertical-align: unset;
}

.star-icon {
  color: $tableBorder;
}

.non-preview-container {
  width: 75px;
  height: 75px;
  border: 0.5px solid $primaryDark;
  border-radius: 5px;
  position: relative;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.pinned-non-preview-icon {
  color: $primary;
  margin-top: 10px;
  font-size: 30px;
}

.pinned-text-container {
  position: absolute;
  bottom: 0;
  width: 65px;
}

.pinned-file-name {
  overflow: hidden;
  white-space: nowrap;
}

.pinned-non-preview-text {
  white-space: nowrap;
  overflow: hidden;
  margin: 0px;
  line-height: normal;
  color: $nonPreviewText;
  font-size: 10px;
  font-weight: bold;
  background-color: #fff;
}
.actions-container {
  position: absolute;
  right: 0;
  top: 20px;
  width: 100px;
  height: auto;
  overflow: visible;
  z-index: 10;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 2.96703px 13.8462px 11.8681px rgba(0, 0, 0, 0.1);
}
.pinned-content {
  color: $primaryDark;
}
.date-show {
  float: left;
  font-size: 8px;
  color: $primaryDark;
}
.actions-list {
  overflow: visible;
  color: $infoMessage;
  cursor: pointer;
}
.actions-list:hover {
  color: $tableBorder;
  background: rgba(255, 255, 255, 0.95);
}
.pinned-container {
  display: flex;
  background: $paginateText;
  height: 75px;
  overflow: hidden;
}
.non-preview-content-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 10px;
  font-weight: bold;
  padding: 1px;
  opacity: 0.7;
}
.v-application {
  line-height: 1.3;
}
.non-preview-file-title {
  overflow: hidden;
  white-space: nowrap;
  background-color: #fff;
}
.pinned-content-container {
  text-align: left;
  margin: 5px;
  overflow: hidden;
}
</style>
