var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._g({staticClass:"table-container"},_vm.subTaskWindow),[_c('v-icon',{staticClass:"close-button",on:{"click":_vm.closeWindow}},[_vm._v("close")]),_c('v-row',{staticClass:"table-row"},[_c('v-col',[_c('table',{staticClass:"table-data"},[_c('thead',[_c('tr',[_c('th',{staticClass:"subtitle"},[_vm._v("Main Task")]),_c('th',{staticClass:"subtitle"},[_vm._v("Sub Task")])])]),_vm._l((_vm.styleTaskList),function(mainTask){return _c('tbody',{key:mainTask.id},[_c('tr',[_c('td',{staticClass:"table-data main-task",class:_vm.insideMainTask == mainTask.id
                  ? 'table-data-clicked'
                  : 'table-data',attrs:{"rowspan":_vm.subTaskList.filter(
                  (task) => mainTask.id == task.style_file_task_id
                ).length + 1}},[_vm._v(" "+_vm._s(mainTask.name)+" ")])]),_vm._l((_vm.subTaskList.filter(
              (task) => mainTask.id == task.style_file_task_id
            )),function(subTask){return _c('tr',{key:subTask.id},[_c('td',{staticClass:"sub-task table-data",on:{"click":function($event){return _vm.subtaskClicked(subTask.style_file_task_id, subTask.id)}}},[_c('span',[_vm._v(_vm._s(subTask.name))])])])})],2)})],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }